export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {

    const deadline = 'September 15 2021 08:00:00 GMT+0200';

    function getTimeRemaining(endtime){
      const total = Date.parse(endtime) - Date.parse(new Date());
      const seconds = Math.floor( (total/1000) % 60 );
      const minutes = Math.floor( (total/1000/60) % 60 );
      const hours = Math.floor( (total/(1000*60*60)) % 24 );
      const days = Math.floor( total/(1000*60*60*24) );

      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      };
    }

    function initializeClock(id, endtime) {
      const clock = document.getElementById(id);
      const timeinterval = setInterval(() => {
        const t = getTimeRemaining(endtime);
        clock.innerHTML = '<div class="col-3 clock-part"><time>' + t.days + '</time>Days</div>' +
                          '<div class="col-3 clock-part"><time>'+ t.hours + '</time>Hours</div>' +
                          '<div class="col-3 clock-part"><time>' + t.minutes + '</time>Minutes</div>' +
                          '<div class="col-3 clock-part"><time>' + t.seconds + '</time>Seconds</div>';
        if (t.total <= 0) {
          clearInterval(timeinterval);
        }
      },1000);
    }

    initializeClock('clockdiv', deadline);

  },
};
