// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/jquery.matchHeight.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import blog from './routes/blog';
import templateEvent from './routes/template-event';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // news page
  blog,
  // events
  templateEvent,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
