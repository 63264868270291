export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    $(function() {
      $('.card').matchHeight();
    });
    // $(function() {
    //   $('.wrapper').matchHeight();
    // });
  },
};
