export default {
  init() {
    // JavaScript to be fired on all pages
    document.addEventListener('wpcf7mailsent', function(event) {
      // console.log('eventlistener works', event);

      if (Object.hasOwn(event.detail.apiResponse, 'custom_msg')) {
        let message = event.detail.apiResponse.custom_msg;
        //console.log('custom_msg', message);

        let ta = event.detail.apiResponse.into;
        //console.log('ta', ta);

        let form = $(ta).find('form');
        //console.log('form', form);

        form.remove();
        $(ta).append(message);
      }
    });

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    // when mobile menu is shown
    $('#navbarToggler').on('show.bs.collapse', function () {
      $('header.banner').addClass('mobileMenuVisible');
    })
    // when mobile menu is hidden
    $('#navbarToggler').on('hidden.bs.collapse', function () {
      $('header.banner').removeClass('mobileMenuVisible');
    })

    var scrollPos = 0;
   // adding scroll event
   window.addEventListener('scroll', function(){
     // detects new state and compares it with the new one
     if ((document.body.getBoundingClientRect()).top > scrollPos)
     //	document.getElementById('info-box').setAttribute('data-scroll-direction', 'UP');
        $('.banner').addClass('scrolled');
     else
     //	document.getElementById('info-box').setAttribute('data-scroll-direction', 'DOWN');
       $('.banner').addClass('scrolled');
     // saves the new position for iteration.
     scrollPos = (document.body.getBoundingClientRect()).top;
     // if is scrolled back to top
     if ((document.body.getBoundingClientRect()).top === 0) {
         $('.banner').removeClass('scrolled');
     }
   });

   $(function() {
     $('.fdcf-icon').matchHeight();
   });
  },
};
